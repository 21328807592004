<template>
  <v-card
    elevation="1"
    shaped
    tile
    :disabled="!address.active"
  >
    <v-card-title
      :class="disabledClass()"
      :disabled="!address.active"
    >
      {{
        address.name
      }}
    </v-card-title>
    <v-card-text :class="disabledClass()">
      {{ address.address }}
    </v-card-text>
    <v-card-text
      :class="disabledClass()"
    >
      {{ address.zip }} {{ address.city }}
    </v-card-text>
    <v-card-text :class="disabledClass()">
      {{ address.country }}
    </v-card-text>
    <v-card-text
      :class="disabledClass()"
    >
      {{ address.contact }} / {{ address.phone }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data: function() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {
    disabledClass() {
      return this.disabled ? 'disabled' : ''
    }
  }
}
</script>

<style scoped>
.disabled {
  color: gray !important;
}
</style>
